import PropTypes from 'prop-types'
import About from './About'
import Projects from './Projects';
import Contact from './Contact';
import Skills from './Skills';

const Window = ({ title }) => {
    let output;
    let identify;
    let section = { title }
    let about = { title: "About" }
    let projects = { title: "Projects" }
    let contact = { title: "Contact" }
    let skill = { title: "Skills" }

    if (JSON.stringify(section) === JSON.stringify(about)) {
        output = <About />;
        identify = "About";
    } else if (JSON.stringify(section) === JSON.stringify(projects)) {
        output = <Projects />;
        identify = "Projects";
    } else if (JSON.stringify(section) === JSON.stringify(contact)) {
        output = <Contact />;
        identify = "Contact";
    } else if (JSON.stringify(section) === JSON.stringify(skill)) {
        output = <Skills />;
        identify = "Skills";
    }


    return (
        <div className="floating">
            <div className="titlebar">
                <a id={identify} aria-hidden="true" href='/'> </a>
                <p className="titlename">&lt;/&gt; &nbsp; {title}</p>
                <hr className="solid"></hr>
                {output}
            </div>
        </div >
    )
}

Window.defaultProps = {
    title: "Title",
}

Window.propTypes = {
    title: PropTypes.string.isRequired,
}

export default Window