import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import logo from '../img/logo.png';

const Navbar = () => {
    return (
        <div className="topnav">
            <div className="navcontent">
                <AnchorLink href="#Home"><img className="logo" src={logo} alt="Logo" /></AnchorLink>
                <AnchorLink href="#About">About Me</AnchorLink>
                <AnchorLink href="#Skills">Education & Skills</AnchorLink>
                <AnchorLink href="#Projects">Projects</AnchorLink>
                <AnchorLink href="#Contact">Contact Me</AnchorLink>
            </div>
        </div>
    )
}


export default Navbar