import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from './components/Header'
import Window from './components/Window';
import Footer from './components/Footer';
import './App.css';


function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <meta name="viewport" content="width=device-width, initial-scale=0.77" />
        <Helmet>
          <title>Ammar Khan</title>
        </Helmet>
        <Header title="Ammar Khan" />
        <Window title="About" />
        <Window title="Skills" />
        <Window title="Projects" />
        <Window title="Contact" />
        <Footer />
      </div>
    </HelmetProvider>
  );
}

export default App;
