import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="footercontent">
                    <div>
                        <p id="footertext">Ammar Khan</p>
                        <p id="copyright">© 2020 – 2023</p>
                    </div>
                    <div>
                        <a href="https://www.linkedin.com/in/ammar-khan-b69999116/" target="_blank" rel="noreferrer"><FontAwesomeIcon id="links" icon={faLinkedin} /></a>
                        <a href="https://github.com/ammarkhan310" target="_blank" rel="noreferrer"><FontAwesomeIcon id="links" icon={faGithub} /></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}


export default Footer
