import React from 'react'
import picture from '../img/picture2.png'

const About = () => {
    return (
        <div className="About">
            <div id="pic">
                <img id="picture" src={picture} alt="It's me!"></img>
            </div>
            <div id="aboutme">
                <h1>About Me</h1>
                <p>Hi welcome to my site!</p>
                <p>My name is Ammar, and I'm a recent graduate of Computer Science at Ontario Tech University. I am a self motivated, hard working individual with a passion for design and efficiency. With knowledge and experience in a multitude of languages, systems, and frameworks, I continue to strive myself to always learn new things. I am detail oriented at heart and love to work in teams and learn from others. My goal is to help others and make a difference in the world with this magical thing we call technology.</p>
            </div>
        </div>
    )
}

export default About
