import React from 'react'
import { useState } from 'react'
import emailjs from 'emailjs-com';
import mail from '../img/letter.svg'

const Contact = () => {
    const [mailResult, setMail] = useState('')
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleChange = (event) => {
        event.target.name === "name"
            ? setName(event.target.value)
            : event.target.name === "email"
                ? setEmail(event.target.value)
                : event.target.name === "message"
                    ? setMessage(event.target.value)
                    : console.log("error")
    };

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_fua88dh', 'template_epeklzs', e.target, 'user_0V9rzZxBULKLDw1moU4bz')
            .then((result) => {
                console.log(result.text);
                setMail(result.text);
                setName("");
                setMessage("");
                setEmail("");
            }, (error) => {
                console.log(error.text);
                setMail(error.text);
            });

        alert('Your email has been sent! ' + mailResult);
    }


    return (
        <div className="Contact">
            <h1>Contact Me</h1>
            <p>I would love to hear from you! Send me a message!</p>

            <div className="contact-container" onSubmit={sendEmail}>
                <form className="form">
                    <input type="text" id="name" name="name" placeholder="Name" onChange={(e) => handleChange(e)} value={name} />
                    <input type="email" id="email" name="email" placeholder="Email" onChange={(e) => handleChange(e)} value={email} />
                    <textarea id="subject" name="message" placeholder="Message" style={{ height: '200px' }} onChange={(e) => handleChange(e)} value={message} />
                    <input id="submit" type="submit" value="Send" />
                </form>
                <img id="mail" src={mail} alt="Mail"></img>
            </div>

        </div>
    )
}

export default Contact
