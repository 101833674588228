import React from 'react'
import uni from '../img/ontechu.jpg'

const Skills = () => {
    return (
        <div className="eduSkills">
            <div className="Education">
                <h1>Education</h1>
                <h3>University of Ontario Institute of Technology</h3>
                <p>Honours Bachelor of Science, Computer Science</p>
                <p>April 2021</p>
                <img id="uni" src={uni} alt="Ontario Tech University"></img>
            </div>
            <div className="Skills">
                <h1 id="skilltitle">Skills</h1>
                <div className="allskills">
                    <div>
                        <h3 id="languages">Langauges</h3>
                        <p id="languages">JavaScript | Python | Java | C++ | C# | C | Dart</p>
                    </div>
                    <div>
                        <h3 id="backend">Backend / Server</h3>
                        <p id="backend">Node.js | Express.js | Spring Boot | Django</p>
                    </div>
                    <div>
                        <h3 id="frontend">Frontend</h3>
                        <p id="frontend">React.js | Vue.js | HTML | CSS SCSS | JQuery </p>
                    </div>
                    <div>
                        <h3 id="databases">Database</h3>
                        <p id="databases">MongoDB | SQL | Cloud Firestore</p>
                    </div>
                    <div>
                        <h3 id="tools">Other Tools</h3>
                        <p id="tools">AWS | Docker | Kubernetes | Tensorflow | Netlify </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills
