import PropTypes from 'prop-types'
import Navbar from './Navbar'

const Header = ({ title }) => {
    return (
        <header>
            <div id="header-bg">
                <Navbar />
                <h1 id="title">{title}</h1>
            </div>
        </header>
    )
}

Header.defaultProps = {
    title: "Ammar Khan",
}

Header.propTypes = {
    title: PropTypes.string.isRequired,
}

export default Header