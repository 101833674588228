import React from 'react'
import tweetbook from '../img/temp.png'
import buzzoff from '../img/buzzoff.png'
import brain from '../img/vae.png'
import twoD from '../img/unity2d.webm'
import threeD from '../img/unity3d.webm'

const Projects = () => {
    return (
        <div className="Project">
            <h1>Projects</h1>


            <div id="projects">
                <div>
                    <h2>TweetBook — Mock Social Media Website</h2>
                    <p>Node.js | MongoDB | Vue.js | Bulma</p>
                    <ul>
                        <li>Built a full stack web application that emulates the functionality of Twitter and Facebook, with a fully fleshed out chat client, a global feed, user accounts and pages, and ‘friend’ functionality</li>
                        <li>Utilized Vue.Js to create frontend with the Bulma CSS framework</li>
                        <li>Server side implemented using Node.Js and MongoDB</li>
                        <li>Incorporated vue-socket.io to create a socket based chat client</li>
                        <li>Made use of JSON Web Tokens for secure data transfer</li>
                    </ul>
                </div>
                <div id="pic" className="tweetbook">
                    <img id="picture" src={tweetbook} alt="TweetBook"></img>
                </div>
            </div>


            <div id="projects">
                <div>
                    <h2>Buzz Off — Mosquito Tracker App</h2>
                    <p>Flutter | Dart | Firestore | SQLite</p>
                    <ul>
                        <li>Created a mosquito tracker application using Flutter/Dart that can be deployed to either Android or iOS</li>
                        <li>Used geolocation to  track the users’ location at the point of the bite along with open weather APIs to display the mosquito level</li>
                        <li>Utilized Cloud Firestore to store global mosquito information and SQLite for local profile storage</li>
                    </ul>
                </div>
                <div id="pic" className="buzz">
                    <img id="picture" src={buzzoff} alt="BuzzOff"></img>
                </div>
            </div>

            <div id="projects">
                <div>
                    <h2>VAE’s for Tumor Detection — Machine Learning Study</h2>
                    <p>Python | NumPy | Tensorflow | Keras</p>
                    <ul>
                        <li>Applied Variational Autoencoders to a brain tumor dataset to explore its denoising ability in a non-conventional manner</li>
                        <li>Built a training model and fit it to the training data subset</li>
                        <li>Results showed that the decoding model ignored the tumors as noise</li>
                        <li>By taking the difference between the decoded and original images, the tumor alone was left visibly highlighted</li>
                    </ul>
                </div>
                <div id="pic">
                    <img id="picture" src={brain} alt="Brain MRI"></img>
                </div>
            </div>

            <div id="projects">
                <div>
                    <h2>The Forgotten Knight — Unity 2D Platformer</h2>
                    <p>Unity 2D | C# | Adobe Photoshop</p>
                    <ul>
                        <li>Built a 2D platformer using the Unity 2D Engine</li>
                        <li>Designed level layout and sprites for the character and stages</li>
                        <li>Scripted player interactions, physics, enemies, menus, and cutscenes using C#</li>
                    </ul>
                </div>
                <div id="pic">
                    <video loop autoPlay muted id="picture">
                        <source src={twoD} type="video/webm" />
                        <source src="https://ammar-portfolio-bucket.s3.ca-central-1.amazonaws.com/unity2d.webm" type="video/webm" />
                    </video>
                </div>
            </div>

            <div id="projects">
                <div>
                    <h2>Into the Wild — Unity 3D Adventure Game</h2>
                    <p>Unity 3D | C# | Blender | Adobe Mixamo</p>
                    <ul>
                        <li>Built a 3D Adventure game using Unity High Definition Render Pipeline</li>
                        <li>Created physics system for interaction, enemies, and movement</li>
                        <li>Scripted Enemy AI, NPCs, menus, and cutscenes</li>
                        <li>Created 3D environment using Unity Terrain</li>
                        <li>Used Adobe Mixamo, MechAnim, and Blender for 3D models and animations</li>
                    </ul>
                </div>
                <div id="pic">
                    <video loop autoPlay muted id="picture">
                        <source src={threeD} type="video/webm" />
                        <source src="https://ammar-portfolio-bucket.s3.ca-central-1.amazonaws.com/unity3d.webm" type="video/webm" />
                    </video>
                </div>
            </div>

            <div id="buttonbox">
                <a href="https://github.com/ammarkhan310" className="gitbutton" target="_blank" rel="noreferrer">Check out my Github for more info!</a>
            </div>

        </div>
    )
}

export default Projects
